import React from 'react'
import Modal from 'react-modal'
import './modalStyles.scss'
import ContactForm from '@/components/ContactForm/ContactForm'

interface ModalContactFormProps {
  toggle: () => void
  isOpen: boolean
}

const ModalContactForm: React.FC<ModalContactFormProps> = ({
  toggle,
  isOpen,
}) => {
  return (
    <>
      <Modal
        isOpen={isOpen}
        className={'Modal'}
        overlayClassName={'Overlay'}
      >
        <ContactForm toggle={toggle} isModal primary />
      </Modal>
    </>
  )
}

export default ModalContactForm
