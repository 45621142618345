import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle<{
  theme: { colors: { blue: string } }
}>`
  body {
    width: 100%;
    background: #F8F8F8;
  }
  
  div {
    box-sizing: border-box;
  }
  
  //ul {
  //  list-style: none;
  //  padding: 0;
  //}
  
  body, h1, h2, h3, h4, h5, h6, p, a {
    margin: 0;
    font-weight: normal;
    font-family: MontserratRegular;
    color: #292849;
    //letter-spacing: 0.5px;
    //line-height: 30px;
  }
  
  a {
    font-family: MontserratRegular;
    text-decoration: none;
    
    
   :hover {
    color: ${({ theme }) => theme.colors.blue};
  }
  }
  
  button {
  
    cursor: pointer;
    outline: none;
    border: none;
    background: none;
  }
  
  input {
    outline: none;
    border: none;
  }
`

export default GlobalStyle
