import styled from 'styled-components'
export const Button = styled.button<{ primary?: boolean }>`
  font-size: ${({ theme }) => theme.fontSize.text};
  color: ${({ primary }) => (primary ? 'white' : 'black')};
  padding-inline: 25px;
  background-color: ${({ theme, primary }) =>
    primary ? theme.colors.blue : 'transparent'};
  border-radius: 3px;
  height: 45px;
  border: ${({ primary }) => (primary ? 'none' : 'solid 1px #353a55;')};

  :hover {
    background-color: ${({ theme, primary }) =>
      primary ? theme.colors.darkBlue : ''};
    color: ${({ primary, theme }) => (primary ? '' : theme.colors.blue)};
    border-color: ${({ primary, theme }) =>
      primary ? 'none' : theme.colors.blue};
  }
`
