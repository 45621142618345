import React, { ChangeEvent } from 'react'
import styled, { css } from 'styled-components'

import Arrow from '../../assets/blueArrow.svg'
interface Props {
  value: string | number
  inputName: string
  label: string
  error?: string | boolean
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void
  options: string[]
  primary?: boolean
}

const Select: React.FC<Props> = (props: Props) => {
  const {
    onChange,
    value,
    label,
    inputName,
    error,
    options,
    primary,
  } = props
  const isEmpty = value === ''

  const optionElements = options.map((item) => (
    <option value={item}>{item}</option>
  ))
  return (
    <InputContainer hasError={error} isEmpty={isEmpty}>
      <Label htmlFor={inputName}>{label}</Label>
      <InputElement
        primary={primary}
        onChange={onChange}
        name={inputName}
        value={value}
      >
        <option value=""> </option>
        {optionElements}
      </InputElement>
      <img src={Arrow} alt={'icon'} />

      {error && <Error>{error}</Error>}
    </InputContainer>
  )
}

const activeLabelStyles = css`
  transform: translate(0, -28px);
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: ${({ theme }) => theme.fontSize.smallText};
`

const InputElement = styled.select<{ primary?: boolean }>`
  //reset default styles
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;

  border-radius: 3px;
  border: solid 1px rgba(41, 40, 73, 0.36);
  font-size: ${({ theme }) => theme.fontSize.semiText};
  padding: 0 0 0 10px;
  z-index: 5;
  width: 240px;
  height: 52px;
  background-color: white;
  transition: border-color 0.2s ease-out;

  @media (min-width: 480px) {
    width: 310px;
  }

  @media (min-width: 755px) {
    width: ${({ primary }) => (primary ? '643px' : '310px')};
  }

  :focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.blue};
  }
  :hover {
    border-color: ${({ theme }) => theme.colors.blue};
  }
`

export const Label = styled.label`
  position: absolute;
  padding: 0 5px;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: ${({ theme }) => theme.colors.darkGray};
  background-color: ${({ theme }) => theme.colors.white};
  transition: transform 0.2s ease-out;
  pointer-events: none;
`

export const InputContainer = styled.div<{
  hasError?: boolean | string
  isEmpty?: boolean
}>`
  img {
    pointer-events: none;
    position: absolute;
    top: 22px;
    width: 14px;
    right: 15px;
  }

  margin-bottom: 30px;
  position: relative;
  @media (max-width: ${({ theme }) => theme.rwd.mobile.maxWidth}) {
    margin-inline: auto;
  }

  ${({ hasError }) =>
    hasError &&
    css`
      ${InputElement} {
        border-color: ${({ theme }) => theme.colors.red};
      }
      ${Label} {
        color: ${({ theme }) => theme.colors.red};
      }
    `}

  ${({ isEmpty }) =>
    !isEmpty &&
    css`
      ${Label} {
        ${activeLabelStyles};
      }
    `};
  :focus-within {
    ${Label} {
      ${activeLabelStyles};
    }

    ${({ hasError }) =>
      !hasError &&
      css`
        ${InputElement} {
          border-color: ${({ theme }) => theme.colors.darkGreen};
        }
      `};
  }

  @media (min-width: ${({ theme }) => theme.rwd.mobile.m}) {
    margin-bottom: 40px;
  }
`

export const Error = styled.span`
  font-size: ${({ theme }) => theme.fontSize.smallText};
  color: ${({ theme }) => theme.colors.red};
  position: absolute;
  bottom: -20px;
  left: 4px;
`

export default Select
