import React, { useState } from 'react'

import styled from 'styled-components'
import { useFormik } from 'formik'
import VALIDATION from '../../constants/validation'
import SELECT_OPTIONS, {
  COUNTRY_OPTIONS,
} from '../../constants/contactFormOptions'
import Input from '../../components/ContactForm/Input'
import contactFormApi, { prepareData } from '../../utils/contactFormApi'
import Select from './Select'
import CloseButton from '@/assets/closeX.svg'
import { Button } from '../StyledComponents/Button'
import SuccessSendModal from '@/components/ContactForm/SuccessSendModal'

const { CHECKOUT_FORM } = VALIDATION
const {
  EMPLOYEES_OPTIONS,
  INTERESTED_IN_OPTIONS,
  TITLE_OPTIONS,
} = SELECT_OPTIONS

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px;
  max-width: 724px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.white};

  @media (max-width: ${({ theme }) => theme.rwd.mobile.s}) {
    padding-inline: 10px;
  }
`

const Form = styled.form``

const InputsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

const Title = styled.h1`
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${({ theme }) => theme.fontSize.smallTitle};
  margin-bottom: 40px;
`

const ModalContent = styled.div`
  position: relative;
  button {
    position: absolute;
    top: -20px;
    right: -20px;

    @media (max-width: ${({ theme }) => theme.rwd.mobile.s}) {
      right: -5px;
    }
  }
`

type Props = {
  isModal?: boolean
  primary?: boolean
  toggle?: () => void
}

const ContactForm: React.FC<Props> = ({ isModal, primary, toggle }) => {
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      company: '',
      numberOfEmployees: '',
      country: '',
      title: '',
      message: '',
      interestedIn: '',
    },
    onSubmit: (data) => {
      contactFormApi(prepareData(data))
      toggleSuccessModal()
    },
    validationSchema: CHECKOUT_FORM,
  })

  const [isSuccessModalOpen, setSuccessModalOpen] = useState(false)

  const toggleSuccessModal = () => {
    setSuccessModalOpen(!isSuccessModalOpen)
    if (toggle && isSuccessModalOpen) {
      toggle()
    }
  }

  const buttonText = primary ? 'Contact Us' : 'Send'

  return (
    <>
      <Wrapper>
        {isModal && (
          <ModalContent>
            <Title>Request a Demo</Title>
            <button onClick={toggle}>
              <img src={CloseButton} alt={'icon'} />
            </button>
          </ModalContent>
        )}
        <Form name="contact" method="post" onSubmit={formik.handleSubmit}>
          <InputsContainer>
            <Input
              type="text"
              label="First Name"
              inputName="firstName"
              value={formik.values.firstName}
              error={formik.touched.firstName && formik.errors.firstName}
              onChange={formik.handleChange}
            />
            <Input
              type="text"
              label="Last Name"
              inputName="lastName"
              value={formik.values.lastName}
              error={formik.touched.lastName && formik.errors.lastName}
              onChange={formik.handleChange}
            />

            <Input
              type="email"
              label="Email"
              inputName="email"
              value={formik.values.email}
              error={formik.touched.email && formik.errors.email}
              onChange={formik.handleChange}
            />

            <Input
              type="text"
              label="Phone"
              inputName="phoneNumber"
              value={formik.values.phoneNumber}
              error={
                formik.touched.phoneNumber && formik.errors.phoneNumber
              }
              onChange={formik.handleChange}
            />

            <Input
              type="text"
              label="Company"
              inputName="company"
              value={formik.values.company}
              error={formik.touched.company && formik.errors.company}
              onChange={formik.handleChange}
            />
            <Select
              label="Number of employees"
              inputName="numberOfEmployees"
              value={formik.values.numberOfEmployees}
              error={
                formik.touched.numberOfEmployees &&
                formik.errors.numberOfEmployees
              }
              onChange={formik.handleChange}
              options={EMPLOYEES_OPTIONS}
            />

            <Select
              value={formik.values.country}
              inputName={'country'}
              label={'Country'}
              error={formik.touched.country && formik.errors.country}
              onChange={formik.handleChange}
              options={COUNTRY_OPTIONS}
            />
            <Select
              value={formik.values.title}
              inputName={'title'}
              label={'I am...'}
              error={formik.touched.title && formik.errors.title}
              onChange={formik.handleChange}
              options={TITLE_OPTIONS}
            />
            {primary ? (
              <Select
                primary
                value={formik.values.interestedIn}
                inputName={'interestedIn'}
                label={'I am interested in...'}
                error={
                  formik.touched.interestedIn && formik.errors.interestedIn
                }
                onChange={formik.handleChange}
                options={INTERESTED_IN_OPTIONS}
              />
            ) : (
              <Input
                isTextArea
                styles={{
                  top: '12%',
                  marginBottom: '10px',
                  width: '100%',
                }}
                type="text"
                label="Write a message..."
                inputName="message"
                value={formik.values.message}
                error={formik.touched.message && formik.errors.message}
                onChange={formik.handleChange}
              />
            )}
          </InputsContainer>

          <PolicyAccept>
            * By clicking Contact Us, I agree to the use of my personal
            data in accordance with Fortress Privacy Policy. Fortress will
            not sell, trade, lease, or rent your personal data to third
            parties.
          </PolicyAccept>

          <ButtonSection>
            <StyledButton primary type="submit">
              {buttonText}
            </StyledButton>
          </ButtonSection>
        </Form>
      </Wrapper>
      <SuccessSendModal
        isOpen={isSuccessModalOpen}
        toggle={toggleSuccessModal}
      />
    </>
  )
}
const StyledButton = styled(Button)`
  margin-top: 20px;
  padding-inline: 75px;
  margin-inline: auto;
`

const ButtonSection = styled.div`
  display: flex;
`

const PolicyAccept = styled.p`
  padding-inline: 10px;
  font-size: ${({ theme }) => theme.fontSize.smallText};
`

export default ContactForm
