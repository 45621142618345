export default {
  colors: {
    white: '#FFFFFF',
    gray: '#e5e5e5',
    darkGray: '#8b8b8b',
    lightGray: '#f5f5f5',
    green: '#71d7a8',
    text: '#212121',
    red: '#BF0000',
    lightRed: '#fff2f5',
    blue: '#0063f7',
    darkBlue: '#0037c6',
    lightBlue: '#0063f7d1',
    cyan: '#87dab9',
  },
  fonts: {
    light: 'MontserratLight',
    regular: 'MontserratRegular',
    medium: 'MontserratMedium',
    semiBold: 'MontserratSemiBold',
    bold: 'MontserratBold',
  },
  fontSize: {
    bigTitle: '56px',
    title: '40px',
    semiTitle: '30px',
    smallTitle: '28px',
    s25: '25px',
    s22: '22px',
    s20: '20px',
    bigText: '18px',
    text: '16px',
    semiText: '14px',
    smallText: '12px',
  },
  rwd: {
    mobile: {
      xs: '380px',
      s: '480px',
      m: '600px',
      maxWidth: '767px',
    },
    tablet: {
      s: '768px',
      m: '900px',
      maxWidth: '1279px',
    },
    desktop: {
      s: '1024px',
      m: '1280px',
      l: '1440px',
      xl: '1600px',
      xxl: '1700px',
    },
  },
}
