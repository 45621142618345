import React, { CSSProperties } from 'react'
import styled from 'styled-components'
import BackgroundImage, { IFluidObject } from 'gatsby-background-image'

const StyledBackgroundImage = styled(BackgroundImage)`
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
`

type Props = {
  image?: IFluidObject | IFluidObject[]
  styles?: CSSProperties
  backgroundColor?: string
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
const BackgroundImageWrapper: React.FC<Props> = ({
  styles,
  image,
  backgroundColor,
  children,
}) => {
  return image ? (
    <StyledBackgroundImage
      alt="backgroundImage"
      style={styles}
      backgroundColor={backgroundColor}
      fluid={image}
    >
      {children}
    </StyledBackgroundImage>
  ) : (
    children
  )
}

export default BackgroundImageWrapper
