import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

type Props = {
  title?: string
  description?: string
  keywords?: string[]
}

const Seo = ({ description, title, keywords }: Props): JSX.Element => {
  const {
    allContentfulSeo: { nodes },
  } = useStaticQuery(graphql`
    {
      allContentfulSeo {
        nodes {
          title
          keywords
          description
          author
          siteUrl
        }
      }
    }
  `)

  const metaDescription = description || nodes[0].description
  const metaTitle = title || nodes[0].title
  const metaKeywords = keywords || nodes[0].keywords

  return (
    <Helmet
      title={metaTitle}
      htmlAttributes={{ lang: 'en' }}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          name: 'keywords',
          content: metaKeywords.join(','),
        },
        {
          name: 'author',
          content: nodes[0].author,
        },
        {
          name: 'siteUrl',
          content: nodes[0].siteUrl,
        },
      ]}
    />
  )
}

export default Seo
