import React, { ChangeEvent, CSSProperties } from 'react'
import styled, { css } from 'styled-components'
import ErrorIcon from '../../assets/formErrorIcon.svg'
interface Props {
  type: 'date' | 'email' | 'number' | 'password' | 'text'
  value: string | number
  inputName: string
  label: string
  error?: string | boolean
  onChange: (
    event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
  ) => void
  styles?: CSSProperties
  isTextArea?: boolean
}

const Input: React.FC<Props> = (props: Props) => {
  const {
    type,
    onChange,
    value,
    label,
    inputName,
    error,
    styles,
    isTextArea,
  } = props
  const isEmpty = value === ''

  return (
    <InputContainer style={styles} hasError={error} isEmpty={isEmpty}>
      <Label style={{ top: styles?.top }} htmlFor={inputName}>
        {label}
      </Label>
      {isTextArea ? (
        <TextArea name={inputName} value={value} onChange={onChange} />
      ) : (
        <InputElement
          style={{ height: styles?.height, width: styles?.width }}
          name={inputName}
          type={type}
          onChange={onChange}
          value={value}
        />
      )}
      {error && (
        <img className={'error-icon'} src={ErrorIcon} alt={'icon'} />
      )}

      {error && <Error>{error}</Error>}
    </InputContainer>
  )
}

const TextArea = styled.textarea`
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: ${({ theme }) => theme.fontSize.semiText};
  display: flex;
  margin: auto;
  resize: none;
  height: 180px;
  border-radius: 3px;
  border: solid 1px rgba(41, 40, 73, 0.36);

  :focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.blue};
  }
  :hover {
    border-color: ${({ theme }) => theme.colors.blue};
  }
  width: 97%;

  @media (max-width: ${({ theme }) => theme.rwd.mobile.s}) {
    width: 88%;
    padding: 20px 20px 0 10px;
  }

  padding: 20px 10px 0 10px;
`

const activeLabelStyles = css`
  transform: translate(0, -28px);
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: ${({ theme }) => theme.fontSize.smallText};
`

const InputElement = styled.input`
  border-radius: 3px;
  border: solid 1px rgba(41, 40, 73, 0.36);
  font-size: ${({ theme }) => theme.fontSize.semiText};
  padding: 0 0 0 10px;
  z-index: 5;
  width: 230px;
  height: 50px;
  transition: border-color 0.2s ease-out;

  @media (min-width: 480px) {
    width: 300px;
  }

  :hover,
  :focus {
    border-color: ${({ theme }) => theme.colors.blue};
  }
`

export const Label = styled.label`
  position: absolute;
  padding: 0 5px;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: ${({ theme }) => theme.colors.darkGray};
  background-color: ${({ theme }) => theme.colors.white};
  transition: transform 0.2s ease-out;
  pointer-events: none;
`

export const InputContainer = styled.div<{
  hasError?: boolean | string
  isEmpty?: boolean
}>`
  margin-bottom: 30px;

  @media (max-width: ${({ theme }) => theme.rwd.mobile.maxWidth}) {
    margin-inline: auto;
  }

  position: relative;

  img {
    position: absolute;
    top: 18px;
    width: 14px;
    right: 15px;
  }

  ${({ hasError }) =>
    hasError &&
    css`
      ${InputElement} {
        border-color: ${({ theme }) => theme.colors.red};
        background-color: ${({ theme }) => theme.colors.lightRed};
      }
      ${Label} {
        color: ${({ theme }) => theme.colors.red};
        background-color: ${({ theme }) => theme.colors.lightRed};
      }
    `}

  ${({ isEmpty }) =>
    !isEmpty &&
    css`
      ${Label} {
        ${activeLabelStyles};
      }
    `};
  :focus-within {
    ${Label} {
      ${activeLabelStyles};
    }
  }

  @media (min-width: ${({ theme }) => theme.rwd.mobile.m}) {
    margin-bottom: 40px;
  }
`

export const Error = styled.span`
  font-size: ${({ theme }) => theme.fontSize.smallText};
  color: ${({ theme }) => theme.colors.red};
  position: absolute;
  bottom: -20px;
  left: 4px;
`

export default Input
