import React from 'react'
import Modal from 'react-modal'
import styled from 'styled-components'
import CloseButton from '@/assets/closeX.svg'

type Props = {
  isOpen: boolean
  toggle?: () => void
}

const Wrapper = styled.div`
  position: relative;
  background-color: white;
  border-radius: 5px;
  max-width: 400px;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-top: #27a427 solid 10px;

  @media (max-width: ${({ theme }) => theme.rwd.mobile.m}) {
    padding: 7px;
  }

  button {
    position: absolute;
    top: 6px;
    right: 3px;
  }
`

const SuccessSendModal: React.FC<Props> = ({ isOpen, toggle }) => {
  return (
    <Modal
      onRequestClose={toggle}
      isOpen={isOpen}
      className={'Modal'}
      overlayClassName={'Overlay'}
    >
      <Wrapper>
        <button onClick={toggle}>
          <img src={CloseButton} alt={'icon'} />
        </button>
        <h2>Message sent successfully.</h2>
        <p>We will get back to you shortly!</p>
      </Wrapper>
    </Modal>
  )
}

export default SuccessSendModal
