import * as Yup from 'yup'
const phoneRegExp = /^((\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const fieldsSchema = {
  firstName: Yup.string()
    .min(2, 'Please enter a valid name')
    .max(20, 'Please enter a valid name')
    .required('Please enter a name'),
  lastName: Yup.string()
    .min(2, 'Please enter a valid last name')
    .max(20, 'Please enter a valid last name')
    .required('Please enter a last name'),
  email: Yup.string()
    .email('Please enter an valid email')
    .required('Please enter an email '),
  phoneNumber: Yup.string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .min(1, 'Please enter a valid phone number')
    .required('Please enter a valid phone number'),
}

export default {
  CHECKOUT_FORM: Yup.object().shape({
    firstName: fieldsSchema.firstName,
    lastName: fieldsSchema.lastName,
    email: fieldsSchema.email,
    phoneNumber: fieldsSchema.phoneNumber,
  }),
}
